import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  StackProps,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import * as React from "react";
import {Link, useNavigate} from "react-router-dom";
import {Field, Form, Formik} from "formik";
import {signupUser} from "../../../api/signup-form";
import {SignupSchema} from "../../../validators/auth-validators";
import {Logo} from "../../../components/Logo";
import {AuthContext} from "../../../auth/auth-context";
import {Helmet} from "react-helmet";
import {
  authenticationErrorMessageGenerator,
  AuthErrorMessage,
} from "../../../utils/authenticationErrorMessageGenerator";

export const SignupForm = (props: StackProps) => {
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { setAuthenticated } = React.useContext(AuthContext);
  const [authErrorMessage, setAuthErrorMessage] = React.useState<AuthErrorMessage | null>(null);

  const handleLoginRedirect = () => {
    // Get the current location
    const from = window.location.pathname;
    if (from === "/signup") {
      // If the signup form is not embedded in another page, no from to restart from
      navigate(`/signin`);
    } else {
      navigate(`/signin?from=${encodeURIComponent(from)}`);
    }
  };

  return (
    <>
      <Helmet title="KUMO | Sign up to get access to deals" />
      <Stack spacing="8" {...props}>
        <Stack spacing="6">
          {isMobile && <Logo />}
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={useBreakpointValue({ base: "xs", md: "sm" })}>
              Create your account
            </Heading>
            <HStack spacing="1" justify="center">
              <Text color="muted">Already have an account?</Text>
              <Button onClick={e => handleLoginRedirect()} variant="link" colorScheme="blue">
                Sign in
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Formik
          initialValues={{
            email: "",
            password: "",
            name: "",
            passwordConfirmation: "",
          }}
          onSubmit={async (values) => {
            try {
              (window as any).gtag("event", "new_customer_sign_up", {
                send_to: "G-EQH88TS87N",
              });
              await signupUser(values);
              setAuthenticated(true);
              setAuthErrorMessage(null);
              navigate("/plan-selection");
            } catch (e: any) {
              const errorState = authenticationErrorMessageGenerator(e.response.data);
              setAuthErrorMessage(errorState);
            }
          }}
          validationSchema={SignupSchema}>
          {({ errors, touched, isSubmitting }) => {
            return (
              <Form>
                <Stack spacing="6">
                  <Stack spacing="5">
                    <Field name="name">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="name">Name</FormLabel>
                          <Input {...field} id="name" placeholder="Enter your name" type="string" />
                          {errors.name && touched.name && (
                            <FormHelperText textColor="red">{errors.name}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="email">Email</FormLabel>
                          <Input
                            {...field}
                            id="email"
                            placeholder="Enter your email"
                            type="string"
                          />
                          {errors.email && touched.email && (
                            <FormHelperText textColor="red">{errors.email}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="password">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="password">Password</FormLabel>
                          <Input {...field} id="password" placeholder="********" type="password" />
                          {errors.password && touched.password && (
                            <FormHelperText textColor="red">{errors.password}</FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                    <Field name="passwordConfirmation">
                      {({ field }: any) => (
                        <FormControl>
                          <FormLabel htmlFor="passwordConfirmation">Confirm Password</FormLabel>
                          <Input
                            {...field}
                            id="passwordConfirmation"
                            placeholder="********"
                            type="password"
                          />
                          {errors.passwordConfirmation && touched.passwordConfirmation && (
                            <FormHelperText textColor="red">
                              {errors.passwordConfirmation}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Stack>
                </Stack>
                {authErrorMessage && (
                  <Text textColor="red" fontSize="sm" mt={2}>
                    {`${authErrorMessage.message}`}
                    {authErrorMessage.link ? (
                      <>
                        {" "}
                        <Text
                          as={Link}
                          variant="link"
                          textColor="red"
                          textDecoration="underline"
                          display="inline"
                          fontSize="sm"
                          to={authErrorMessage.link}>
                          sign in here.
                        </Text>
                      </>
                    ) : (
                      ""
                    )}
                  </Text>
                )}
                <Stack spacing="4" mt={6}>
                  <Button type="submit" variant="primary" isLoading={isSubmitting}>
                    Sign up
                  </Button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Stack>
    </>
  );
};
